@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import "~normalize.css/normalize.css";
:root {
  --font-sans: "DM Sans", sans-serif;
  --font-base: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
  --font-mono: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
  --tw-bg-opacity:1;
  --tw-bg-opacity-1:0.1;
  --tw-bg-opacity-2:0.2;
  --tw-bg-opacity-3:0.3;
  --tw-bg-opacity-8:0.8;
  --tw-bg-opacity-9:0.9;
  --primary-color: #eff7ff;
  --secondary-color: #80b1e3;
  --purple-color: #8180E3;
  --black-color: #12121ce1;
  --lightGray-color: #f0f0f0;
  --gray-color: #808f9e;
  --white-color:#efefef;
  --lightBlue-color: #c0d8f1;
  --brightPurple-color:#a16ae4;
  --lightPurple-color:#8e8de6;
  --pink-purple-color:#B380E3;
  --brightBlue-color:#9ccdff;
  --lightPurle-color:#d9d9f7;
  --gradient-color1: #80b1e3;
  --gradient-color2: #7271e2;
  --gradient-color3:#a570e7;
  --bg-gradient:#1a232d;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html, body{
  height: 100%;
  min-height: 100vh;
  background-color: var(--bg-gradient);
}
body{  
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  font-family: var(--font-base);
}
body{
  scrollbar-width: thin;
  scrollbar-color: var(--secondary-color) var(--bg-gradient);
}
body::-webkit-scrollbar {
  width: 11px;
}
body::-webkit-scrollbar-track {
  background: var(--bg-gradient);
}
body::-webkit-scrollbar-thumb {
  background: var(--secondary-color) ;
  border-radius: 10px;
}

.hero-gradient,
.gradient-02,
.gradient-03,
.footer-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(80px);
  -webkit-filter: blur(80px);
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  transform: translate3d(0,0,0);
  transform: translateZ(0);
}

.hero-gradient {
  background-image:
    radial-gradient(at 55% 40%, rgba(101, 101, 255, 0.7) 0px, transparent 35%),
    radial-gradient(at 75% 0%, rgba(214, 101, 255, 0.7) 0px, transparent 40%),
    radial-gradient(at 35% 0%, rgba(158, 114, 255, 0.7) 0px, transparent 40%);
}

.gradient-02 {
  background-image:
    radial-gradient(at 19% 85%, var(--gradient-color2) 0px, transparent 14%),  
    radial-gradient(at 86% 21%, var(--gradient-color3)  0px, transparent 12%);
}

.gradient-03 {
  width: 300px;
  height: 300px;
  right: 9%;
  top: 10%;
  background: rgba(121, 55, 186, 0.55);
  transform: rotate(-114.2deg);
}

.footer-gradient {
  height: 125px;
  right: 0;
  bottom: 0;
  background: rgba(120, 202, 249, 0.55);
}

.app__container {
  margin: 0px auto;
  max-width: 1600px;
  height: 100%;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 550px) {
    display: flex;
    flex-direction: column;
  }
}
.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;
}
.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--white-color);
  text-transform: capitalize;

  span {
    color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 1.75rem;
  }
}
.head-text-custom {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  color: var(--white-color);
  text-transform: capitalize;

  span {
    color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 1.25rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;
  
}
.p-text-sm {
  font-size: 0.7rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;

}
.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: var(--white-color);
  text-align: left;

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
}
.copyright {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  p {
    text-transform: uppercase;
    color: var(--white-color);
  }
}
.app__social {
  position: relative;
  will-change: transform;
  transform: translateZ(0);
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  li {display: inline-block; margin: 0.5rem;}
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(64, 89, 114, var(--tw-bg-opacity-3));
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    svg {
      width: 15px;
      height: 15px;
      color: var(--white-color);
    }
    
    &:hover, &:focus{
      transform: translateY(-5px) !important;
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);
  
      svg {
        color: var(--white-color);
      }
    }
  }
}
.loader-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: var(--bg-gradient);
  -webkit-transition: all 1 ease-in-out;
  -moz-transition: all 1 ease-in-out;
  -ms-transition: all 1 ease-in-out;
  -o-transition: all 1 ease-in-out;
  transition: all 1 ease-in-out;
  p{
    text-align: center;
    padding: 1rem;
  }
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
   }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}